import React from "react";
import {
  Container,
  Row, Col,
} from 'react-bootstrap';
import { Link } from "react-router-dom";
import Wave  from './wave';

const AboutSection = (props) => {
	return(
		<section id="hp-about-section">
			<Container>
				<Row id="hp-about-row">
					<Col lg={5}>
						<div className="section-heading">
							<h2>קצת עלי</h2>
							<p>עמית מורנו עוסק בעיצוב ופיתוח מיזמים טכנולוגיים ודיגיטליים כבר למעלה מתריסר שנים. עיקר הפעילות של עמית מתנהלת בבית התוכנה ״קוד אנד רול״ שבבעלותו, המציע שירותי פיתוח מקיפים מקצה לקצה עבור פרויקטים טכנולוגיים כמו למשל אתרי אינטרנט מורכבים, חנויות מסחר דיגיטליות, אפליקציות נאטיב, מערכות ניהול לעסקים, מערכות לימוד ועוד.</p>
							<nav className="nav-horizontal" style={{
								marginTop: 45,
							}}>
								<Link to={'/about'} className="btn btn-default">אודות עמית מורנו</Link>
							</nav>
						</div>
					</Col>
					<Col lg={7}>
						<ul id="accomplish-list">
							<li>
								<h3><span>12+</span>שנים של ניסיון</h3>
								<p>פעיל באופן עצמאי משנת 2011 ומהימן על ידי קשת רחבה של שותפים.</p>
							</li>
							<li>
								<h3><span>125+</span>פרויקטים באוויר</h3>
								<p>הבנה ושליטה מלאה בטכנולוגיות מודרניות וטכנולוגיות קצה.</p>
							</li>
							<li>
								<h3><span>25+</span>שרתי ענן מנוהלים</h3>
								<p>הכוללים ביצועים גבוהים, גמישות וסקלאביליות, ותמיכה מתמשכת.</p>
							</li>
							<li>
								<h3><span>2+</span>שנים בממוצע לפרויקט</h3>
								<p>הלקוחות שלנו אוהבים להישאר אתנו לטווח הארוך ולפתח יחד את החלומות שלהם.</p>
							</li>
							
						</ul>
					</Col>
				</Row>
			</Container>
			<Wave />
        </section>
	);
}

export default AboutSection;