import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import {
  Container, Breadcrumb,
} from 'react-bootstrap';
import { HomeFilled } from '@ant-design/icons';

const Technologies = (props) => {	
	useEffect(() => {
		props.declareAppClasses();
		document.title = "טכנולוגיות | עמית מורנו";
	}, [props]);
		
	return(
		<div className="page-wrap">
			<header className="page-header">
				<Container>
					<h1>טכנולוגיות</h1>
					<Breadcrumb>
						<li className="breadcrumb-item"><Link to={'/'} title="ניווט לעמוד הראשי"><HomeFilled /><span className="sr-only">ניווט לעמוד הראשי</span></Link></li>
						<Breadcrumb.Item active>טכנולוגיות</Breadcrumb.Item>
					</Breadcrumb>
				</Container>
			</header>
			
			<section id="tech-view">
				<Container>
					<p>בעבודה שלנו עם סטארט-אפים ומיזמים חדשניים אנו נחשפים לעיתים קרובות לטכנולוגיות מתקדמות, בוחנים את היכולות שלהן, יציבותן, גמישותן והאם הן מתאימות לפרויקטים שאנו מפתחים. אנו מאמינים בשילוב של חדשנות לצד יציבות, על מנת לספק ללקוחותינו מוצר מנצח ויעיל לטווח ארוך.</p>
					<p>כחלק מתהליך הפיתוח שלנו אנו מתאימים את השיטות והטכנולוגיות המתאימות לפרויקט דרך היכרות עם צרכי הלקוח, השוק והסביבה העסקית של המוצר. אנו מציעים ללקוחותינו מגוון רחב של פתרונות טכנולוגיים המאפשרים להם לבחור בבטחה את הפתרון המתאים ביותר לצרכיהם.</p>
					<p>
לצד העבודה העסקית שלנו, אנו תורמים לטכנולוגיות קוד פתוח ומפתחים בעצמנו פתרונות המופצים בקוד פתוח וברישיון חופשי על מנת לקדם חדשנות וקידמה.</p>
					<div id="tech-providers">
						<h2>פיתוח אפליקציות</h2>
						<ul>
							<li className="box box-bordered">iOS</li>
							<li className="box box-bordered">Android SDK</li>
							<li className="box box-bordered">React Native</li>
							<li className="box box-bordered">Ionic</li>
							<li className="box box-bordered">Express</li>
							<li className="box box-bordered">Expo</li>
						</ul>
						
						<h2>צד שרת</h2>
						
						<ul>
							<li className="box box-bordered">Node.js</li>
							<li className="box box-bordered">Grunt.js</li>
							<li className="box box-bordered">Gulp.js</li>
							<li className="box box-bordered">PHP</li>
							<li className="box box-bordered">symfony</li>
							<li className="box box-bordered">Laravel</li>
							<li className="box box-bordered">CodeIgniter</li>
						</ul>
						
						
						
						<h2>צד לקוח</h2>
						
						<ul>
							<li className="box box-bordered">React</li>
							<li className="box box-bordered">Angular</li>
							<li className="box box-bordered">jQuery</li>
							<li className="box box-bordered">HTML5</li>
							<li className="box box-bordered">CSS3</li>
							<li className="box box-bordered">Sass</li>
						</ul>
						
						<h2>מסדי נתונים</h2>
						
						<ul>
							<li className="box box-bordered">MySQL</li>
							<li className="box box-bordered">SQLite</li>
							<li className="box box-bordered">PostgreSQL</li>
							<li className="box box-bordered">MongoDB</li>
							<li className="box box-bordered">MariaDB</li>
							<li className="box box-bordered">DynamoDB</li>
						</ul>
						
						
						<h2>בדיקות QA ו-Deployment</h2>
						
						<ul>
							<li className="box box-bordered">Jasmine.js</li>
							<li className="box box-bordered">Lambda</li>
							<li className="box box-bordered">Selenium</li>
							<li className="box box-bordered">Karma</li>
							<li className="box box-bordered">Jest</li>
							<li className="box box-bordered">Webpack</li>
							<li className="box box-bordered">Compass</li>
							<li className="box box-bordered">NPM</li>
							<li className="box box-bordered">YARN</li>
						</ul>
						
						<h2>ניהול פרויקטים</h2>
						
						<ul>
							<li className="box box-bordered">Git</li>
							<li className="box box-bordered">InvisionApp</li>
							<li className="box box-bordered">ASANA</li>
							<li className="box box-bordered">Jira</li>
							<li className="box box-bordered">Harvest</li>
						</ul>
						
						<h2>מערכת ניהול תוכן</h2>
						
						<ul>
							<li className="box box-bordered">WordPress</li>
							<li className="box box-bordered">Magento</li>
							<li className="box box-bordered">Moodle</li>
							<li className="box box-bordered">HubSpot</li>
						</ul>
						
						{/*
						<h2>And More ...</h2>
						<ul>
							<li className="box box-bordered">Socket.io</li>
						</ul>
						*/}
					</div>
					
				</Container>
        	</section>
		</div>
	);
}

export default Technologies;