import React, {useState} from "react";
import { Form, Button } from 'react-bootstrap';
import { LoadingOutlined, CheckCircleOutlined } from '@ant-design/icons';

const ContactForm = (props) => {
	const [fullname, setFullname] = useState('');
	const [company, setCompany] = useState('');
	const [email, setEmail] = useState('');
	const [role, setRole] = useState('');
	const [message, setMessage] = useState('');
	const [phone, setPhone] = useState('');
	const [errors, setErrors] = useState([]);
	const [disabled, setDisabled] = useState(true);
	const [sending, setSending] = useState(false);
	const [showSuccess, setShowSuccess] = useState(false);
	
	const addTask = async (e) => {
	 	e.preventDefault();
	 	
		try {
			setSending(true);
			
			let task = 'Name: ' + fullname + ' \n';
			task += 'Company: ' + company + ' \n';
			task += 'Tel: ' + phone + ' \n';
			task += 'Email: ' + email + ' \n';
			task += 'Role: ' + role + ' \n';
			task += 'Message: ' + message + ' \n';
			task += '\n\nSource: Amitmoreno.com';
			
			const response = await fetch('https://app.asana.com/api/1.0/tasks?opt_fields=name,due_on,completed,memberships.section.name&project=1202549578523471', {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer 1/76469592662994:3dc4db3c60625604a4407d39af524d1a',
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "data": {
                      "approval_status": "pending",
                      "completed": false,
                      "external": {
                        "data": "A blob of information",
                      },
                      "html_notes": "<body>" + task + "</body>",
                      "name": 'Lead: ' + fullname,
                      "notes": fullname,
                      "projects": [
                        '1202549578523471'
                      ],
                      "resource_subtype": "default_task",
                      
                      "workspace": "76418763542074"
                    }
                  })
            });
			const json = await response.json();
            if( json.data ){
                setCompany('');
                setFullname('');
                setEmail('');
                setErrors([]);
                setMessage('');
                setPhone('');
                setRole('');
                setShowSuccess(true);
                setSending(false);
            }
            // setSending(false);
            // setSuccess(true);
		  } catch (error) {
			  //console.error(error);
		  } finally {
			//setLoading(false);
		}
	};
	
  
    const validate = () => {
        let collectErrors = [];
        
        var re = /\S+@\S+\.\S+/;

        if( fullname.length <= 1 || !fullname )
        collectErrors.push('fullname');
        
        if( !re.test(email) )
        collectErrors.push('email');
        
        if( message.length < 2 )
        collectErrors.push('message');

        if( collectErrors.length === 0 ){
        setErrors(collectErrors);
        setDisabled(false);
        } else {
        setDisabled(true);
        }
    }
	
	return(
		<div id="contact-form">
            {!sending && !showSuccess &&
            <Form
                name="contact-project"
                //initialValues={{ remember: true }}
                //onFinish={onFinish}
                //onFinishFailed={onFinishFailed}
            >
                <Form.Group controlId="fullname" className={errors.includes('fullname') ? 'is-error' : ''}>
                    <Form.Label>שם מלא *</Form.Label>
                    <Form.Control type="text" name="fullname" value={fullname} onChange={(e) => {
                        validate();
                        setFullname(e.target.value);
                    }} />
                    {errors.includes('fullname') &&
                        <p>נא להזין גם שם פרטי וגם שם משפחה. </p>
                    }
                </Form.Group>
                              
                <Form.Group controlId="company">
                    <Form.Label>שם החברה</Form.Label>
                    <Form.Control type="text" name="company" value={company} onChange={(e) => {
                        validate();
                        setCompany(e.target.value);
                    }} />
                </Form.Group>
                              
                <Form.Group controlId="email" className={errors.includes('email') ? 'is-error' : ''}>
                    <Form.Label>דואר אלקטרוני *</Form.Label>
                    <Form.Control type="email" name="email" value={ email} onChange={(e) => {
                        validate();
                        setEmail(e.target.value);
                    }} />
                    {errors.includes('email') &&
                        <p>נא להזין כתובת דואר אלקטרוני.  </p>
                    }
                </Form.Group>
                              
                <Form.Group controlId="phone">
                    <Form.Label>טלפון</Form.Label>
                    <Form.Control type="tel" name="phone" value={phone} onChange={(e) => {
                        validate();
                        setPhone(e.target.value);
                    }} />
                </Form.Group>
                              
                <Form.Group controlId="role">
                    <Form.Label>מה התפקיד שלך?</Form.Label>
                    <Form.Control as="select" name="role" value={role} onChange={(e) => {
                        validate();
                        setRole(e.target.value);
                    }}>
                        <option value=""></option>
                        <option value="clevel">C-level/SVP</option>
                        <option value="director">VP/Director</option>
                        <option value="manager">Manager</option>
                        <option value="individual">Individual Contributor</option>
                        <option value="other">אחר</option>
                    </Form.Control>
                </Form.Group>
                              
                <Form.Group controlId="message" className={errors.includes('message') ? 'is-error' : ''}>
                    <Form.Label>תוכן ההודעה *</Form.Label>
                    <Form.Control as="textarea" rows={3} name="message" value={message} onChange={(e) => {
                        validate();
                        setMessage(e.target.value);
                    }} />
                    {errors.includes('message') &&
                        <p>נא לפרט את פרטי ההודעה שלך ככל האפשר.  </p>
                    }
                </Form.Group>
                {/*
                <Form.File id="attach" style={{
                marginBottom: 30,
                }}>
                <Form.File.Label>Attach File</Form.File.Label>
                <Form.File.Input name="file" value={ form_data.file} onChange={updateForm} />
                </Form.File>
                */}
                <Button type="submit" variant="primary" disabled={disabled} onClick={addTask} style={{
                    marginTop: 45,
                }}>שליחת בקשה</Button>
            </Form>
            }
            {sending &&
                <div id="contact-loader"><LoadingOutlined /></div>
            }
            {showSuccess &&
                <div id="contact-success">
                <div>
                    <CheckCircleOutlined />
                </div>
                <h5 style={{
                    margin: '15px 25% 60px',
                }}>הבקשה שלך נשלחה בהצלחה.</h5>
                </div>
            }
        </div>
	);
}

export default ContactForm;