import React, {useEffect} from "react";

import Hero from '../components/hero';
import AboutSection from '../components/aboutSection';
import ServicesSection from '../components/servicesSection';
import CaseStudiesSection from '../components/caseStudiesSection';

const Home = (props) => {
	useEffect(() => {
		props.declareAppClasses();
        document.title = "בניית אתרים ופיתוח תוכנה מקצה לקצה | עמית מורנו";
	});
	return(
		<div className="page-wrap">
			<Hero />
			<AboutSection />
			<ServicesSection />
			<CaseStudiesSection />
		</div>	
	);
}

export default Home;