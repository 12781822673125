import React, { useState, useEffect } from "react";
import {
  Container,
  Row, Col,
} from 'react-bootstrap';
import { Link } from "react-router-dom";


const CaseStudiesSection = (props) => {
	const [cases, setCases] = useState([]);
	
	let Item1 = cases.length > 0 ? cases[0] : '';
	let Item2 = cases.length > 1 ? cases[1] : '';
	let Item3 = cases.length > 2 ? cases[2] : '';
	let Item4 = cases.length > 3 ? cases[3] : '';
	
	useEffect(() => {
		fetch("https://admin.codenroll.co.il/wp-json/wp/v2/case_study", {})
            .then(response => {
                return response.json();
            })
            .then(results => {
            	setCases(results);
            });
	}, []);

	return(
		<section id="hp-case-studies">
			<Container>
				<Row className="section-heading-wrap">
					<Col sm={12}>
						<div className="section-heading">
							<h2>פרויקטים אחרונים</h2>
						</div>
					</Col>
				</Row>
				<Row id="hp-use-cases-row">
					<Col sm={4}>
					    {Item1 !== '' &&
						<Link to={'/projects/' + Item1.slug} className="box box-image uc-box-long">
							<div className="bg" style={{
							    backgroundImage: 'url(' + Item1.full_image + ')',
							}}></div>
							<div className="textual">
								<h3>{ Item1.title_heb }</h3>
							</div>
						</Link>
					    }
					</Col>
					<Col sm={8}>
						<Row>
							<Col sm={12}>
							    {Item2 !== '' &&
								<Link to={'/projects/' + Item2.slug} className="box box-image uc-box-wide">
									<div className="bg" style={{
							    backgroundImage: 'url(' + Item2.full_image + ')',
							}}></div>
									<div className="textual">
										<h3>{ Item2.title_heb }</h3>
									</div>
								</Link>
							    }
							</Col>
							<Col sm={6}>
							    {Item3 !== '' &&
								<Link to={'/projects/' + Item3.slug} className="box box-image uc-box-small">
									<div className="bg" style={{
							    backgroundImage: 'url(' + Item3.full_image + ')',
							}}></div>
									<div className="textual">
										<h3>{ Item3.title_heb }</h3>
									</div>
								</Link>
							    }
							</Col>
							<Col sm={6}>
							    {Item4 !== '' &&
								<Link to={'/projects/' + Item4.slug} className="box box-image uc-box-small">
									<div className="bg" style={{
							    backgroundImage: 'url(' + Item4.full_image + ')',
							}}></div>
									<div className="textual">
										<h3>{ Item4.title_heb }</h3>
									</div>
								</Link>
							    }
							</Col>
						</Row>
					</Col>
				</Row>
				<div style={{
					textAlign: 'center',
					marginTop: 45,
				}}>
					<a href="/projects" className="btn btn-primary">אל תיק העבודות המלא</a>
				</div>
			</Container>
        </section>
	);
}

export default CaseStudiesSection;