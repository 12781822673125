
import ToggleButton from 'react-toggle-button';
import React, {useState} from "react";
import { CloseOutlined } from '@ant-design/icons';
import { AccessibilityContext } from '../accessibility-provider';
const toggleClass = (el, className) => el.classList.toggle(className);

const AccessibilityToolbar = (props) => {
    const [toggle, setToggle] = useState(false);

    const value = React.useContext(AccessibilityContext);
    const [animations, setAnimations] = value.animations;
    // const [inctext, setInctext] = value.inctext;
    // const [dectext, setDectext] = value.dectext;
    const [darkconstrast, setDarkconstrast] = value.darkconstrast;
    const [brightconstrast, setBrightconstrast] = value.brightconstrast;
    const [readable, setReadable] = value.readable;
    
    return(
        <div>
            <button 
                onClick={() => setToggle( !toggle )}
            type="button" id="awp-toggle" tabindex="0" aria-label="Toggle Accessibility Toolbar">
            <svg  fill="#181c32" focusable="false" width="30" height="30" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20">
                <path d="M10 2.6c.83 0 1.5.67 1.5 1.5s-.67 1.51-1.5 1.51c-.82 0-1.5-.68-1.5-1.51s.68-1.5 1.5-1.5zM3.4 7.36c0-.65 6.6-.76 6.6-.76s6.6.11 6.6.76s-4.47 1.4-4.47 1.4s1.69 8.14 1.06 8.38c-.62.24-3.19-5.19-3.19-5.19s-2.56 5.43-3.18 5.19c-.63-.24 1.06-8.38 1.06-8.38S3.4 8.01 3.4 7.36z"></path>
            </svg>
        </button>
            <div id="accessibility-toolbar" className={toggle ? 'active' : ''}>
                <p id="toolbar-heading">כלי נגישות</p>
                <button id="close-toolbar" onClick={() => setToggle( !toggle )}>
                    <CloseOutlined />
                </button>
                <div id="accessibility-toolbar-buttons">
                    <Toggler 
                        action={() => {
                            toggleClass(document.querySelector('body'), 'stop-animations');
                            setAnimations( !animations );
                        }}
                        label="ביטול הבהובים ואנימציות"
                        value={animations}
                    />
                    {/* <Toggler 
                        action={() => {
                            setInctext( !inctext );
                        }}
                        label="הגדלת טקסט"
                        value={inctext}
                    />
                    <Toggler 
                        action={() => {
                            setDectext( !dectext );
                        }}
                        label="הקטנת טקסט"
                        value={dectext}
                    /> */}
                    <Toggler 
                        action={() => {
                            setBrightconstrast( false );
                            document.body.classList.remove('brightconstrast');
                            ///
                            toggleClass(document.querySelector('body'), 'darkconstrast');
                            setDarkconstrast( !darkconstrast );
                        }}
                        label="ניגודיות כהה"
                        value={darkconstrast}
                    />
                    <Toggler 
                        action={() => {
                            setDarkconstrast( false );
                            document.body.classList.remove('darkconstrast');
                            
                            toggleClass(document.querySelector('body'), 'brightconstrast');
                            setBrightconstrast( !brightconstrast );
                        }}
                        label="ניגודיות בהירה"
                        value={brightconstrast}
                    />
                    <Toggler 
                        action={() => {
                            toggleClass(document.querySelector('body'), 'readable');
                            setReadable( !readable );
                        }}
                        label="גופן קריא"
                        value={readable}
                    />
                </div>
            </div>
        </div>
    );
}


const Toggler = (props) => {
    return(
        <div className="toggler" role="button" onClick={props.action}>
            <div className="toggler-label">{props.label}</div>
            <div className="toggler-button">
                <ToggleButton
                    value={ props.value }
                    inactiveLabel={''}
                    activeLabel={''}
                    colors={{
                        // activeThumb: {
                        //     base: 'rgb(250,250,250)',
                        // },
                        // inactiveThumb: {
                        // base: 'rgb(62,130,247)',
                        // },
                        active: {
                            base: 'rgb(27,197,198)',
                            // hover: 'rgb(177, 191, 215)',
                        },
                        // inactive: {
                        // base: 'rgb(65,66,68)',
                        // hover: 'rgb(95,96,98)',
                        // }
                    }}
                    onToggle={(value) => {
                        // setAnimations( !animations );
                    }} 
                />
            </div>
        </div>
    );
}

export default AccessibilityToolbar;