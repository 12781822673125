import React from "react";
import { Container } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Lottie from 'react-lottie';
import * as animationData from './animation-1.json';
import { AccessibilityContext } from '../accessibility-provider';

const Hero = (props) => {
    const value = React.useContext(AccessibilityContext);
    const [animations] = value.animations;

    const Title = window.innerWidth > 700 ? <h1><span className="top">ביצועים מקסימאליים</span> <span className="hand-writing">בניית אתרים ופיתוח תוכנה לעסקים וסטארטאפ</span></h1> : <h1><span className="top">בניית אתרים ופיתוח תוכנה לעסקים וסטארטאפ</span></h1>;
    return(
		<header id="home-hero">
			<Container>
                {Title}
				<p className="lead">עמית מורנו מציע שירותי בניית אתרים ופיתוח תוכנה מקצה לקצה, משלב האפיון וחווית המשתמש, ועד לפיתוח מקצועי ריספונסיבי וסקלאבילי באמצעות הטכנולוגיות המודרניות במשק.</p>
				<nav>
					<Link to={'/projects'} className="btn btn-transparent">תיק עבודות</Link>
					<Link to={'/contact'} className="btn btn-transparent">בואו נדבר</Link>
				</nav>
				{!animations &&
				<div id="hero-animation">
					<Lottie options={{
				      loop: false,
				      autoplay: true, 
				      animationData: animationData,
				      rendererSettings: {
				        preserveAspectRatio: 'xMidYMid slice'
				      }
				    }}
		              //height={400}
		              width={880}
	            	/>
	            </div>
                }
			</Container>
		</header>
	);
}

export default Hero;