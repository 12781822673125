import React, {useEffect, useState} from "react";
import {
  Row, Col, Container, Breadcrumb,
} from 'react-bootstrap';
import parse from 'html-react-parser';
import ContactForm from '../components/contactForm';

import { Link, useParams } from "react-router-dom";
import { HomeFilled } from '@ant-design/icons';
import Wave  from '../components/wave';
import CaseStudiesSection from '../components/caseStudiesSection';

const Service = (props) => {
    const [title, setTitle] = useState('');
    const [data, setData] = useState(null);
    const [pageid, setPageid] = useState(null);

	let match = useParams();

	useEffect(() => {
        let slug = match.slug;
        let pageTitle = '';
        let pageID = null;

        switch (slug) {
            case 'בניית-אתרים' : pageTitle = 'בניית אתרים'; pageID = 1299; break;
            case 'חווית-משתמש' : pageTitle = 'חווית משתמש UX/UI'; pageID = 1308; break;
            default : pageTitle = ''; pageID = null;
        }

        setTitle( pageTitle );
        setPageid( pageID );
        window.scrollTo({top: 0, behavior: 'smooth'});
		document.title = pageTitle + " | עמית מורנו";
		props.declareAppClasses();
        
	}, [match.slug, props]);

    useEffect(() => {
        if( pageid ){
            fetch("https://admin.codenroll.co.il/wp-json/wp/v2/pages/" + pageid, {})
            .then(response => {
                return response.json();
            })
            .then(results => {
	              setData(results);
            });
        }
		
        ////
        // fetch("https://admin.codenroll.co.il/wp-json/wp/v2/posts?per_page=4", {})
        //     .then(response => {
        //         return response.json();
        //     })
        //     .then(results => {
        //         setRecents(results);
        //     });
		
	}, [pageid]);
	return(
		<div className="page-wrap page-wrap-service">
	        <header className="page-header page-header-with-lead">
	            <Container>
                    <Row>
		        		<Col lg={9} md={9}>
                            <h1>{title}</h1>
                            
                            <Breadcrumb>
                                <li className="breadcrumb-item"><Link to={'/'} title="ניווט לעמוד הראשי"><HomeFilled /><span className="sr-only">Navigate to Home page</span></Link></li>
                                <Breadcrumb.Item active>שירותים</Breadcrumb.Item>
                                <Breadcrumb.Item active>{title}</Breadcrumb.Item>
                            </Breadcrumb>

                            {data && data.excerpt &&
                            <div className="lead">{parse(data.excerpt.rendered)}</div>
                            }
                        </Col>
                    </Row>
	            </Container>
	          </header>
	          
	        <section id="service-view">
	        	<Container>
	        		<Row>
		        		<Col lg={7} md={7}>
                            {data && data.content &&
							<div className="box box-bordered">
                                {parse(data.content.rendered)}
							</div>
                            }
						</Col>
                        <Col lg={5} md={5}>
                            <div className="box box-bordered">
                                <h2>זה הזמן לשוחח על הפרויקט שלך!</h2>
                                <ContactForm />
							</div>
                        </Col>
                        <Col lg={12} md={12}>
                            <CaseStudiesSection />
                        </Col>
					</Row>
	        	</Container>
	        	
	        	<Wave />
	        </section>
	        
		</div>
	);
}

export default Service;