import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import {
  Container, Breadcrumb,
} from 'react-bootstrap';
import { HomeFilled } from '@ant-design/icons';

const Accessibility = (props) => {	
	useEffect(() => {
		props.declareAppClasses();
		document.title = "הצהרת נגישות | עמית מורנו";
	}, [props]);
		
	return(
		<div className="page-wrap">
			<header className="page-header">
				<Container>
					<h1>הצהרת נגישות</h1>
					<Breadcrumb>
						<li className="breadcrumb-item"><Link to={'/'} title="ניווט לעמוד הראשי"><HomeFilled /><span className="sr-only">ניווט לעמוד הראשי</span></Link></li>
						<Breadcrumb.Item active>הצהרת נגישות</Breadcrumb.Item>
					</Breadcrumb>
				</Container>
			</header>
			
			<section>
				<Container>
                    <p>הצהרת נגישות זו היא עבור אתר האינטרנט של עמית מורנו הנמצא בכתובת Amitmoreno.com ושייך ומופעל על ידי בוטיק התוכנה קודנרול (להלן: ״האתר״).</p>
                    <p>קודנרול שמה לעצמה יעד מרכזי לספק כלים טכנולוגיים ודיגיטליים הנגישים לציבור הרחב, הצוותים שלנו עוברים הדרכות קבועות כדי שנוכל להמשיך להפיק טכנולוגיות נגישות. אנו בקודנרול עושים מאמצים רבים על מנת להנגיש את הכלים הטכנולוגיים והדיגיטליים שלנו לכלל הציבור, ובכלל זה גם את אתר אינטרנט זה.</p>
                    <p>אתר האינטרנט של עמית מורנו עובר בדיקות פורמליות תקופתיות כדי לשמור על רמת נגישות נאותה ואנו נמשיך לשמור על כך. האתר מונגש לשימוש עם טכנולוגיות מסייעות דרך הדפדפנים: כרום ופיירפוקס.</p>
                    <p>נשמח לקבל ממך פידבק על רמת הנגישות באתר. אנא צרי/צור קשר עם נציג הנגישות שלנו במידה ונתקלת בכשל נגישות כלשהו. פרטי יצירת הקשר עם נציג הנגישות שלנו הם:</p>
                    <ul>
                        <li>בדואר אלקטרוני: <a href="mailto:a@codenroll.co.il">office@Codenroll.co.il</a></li>
                        <li>בטלפון: <a href="tel:0534626311">053-4626311</a> (עמית)</li>
                    </ul>
                    <p>נשתדל לתת מענה תוך 5 ימי עסקים.</p>
				</Container>
        	</section>
		</div>
	);
}

export default Accessibility;