// Fundamentals
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

// Utilities

// User Interface
import { stack as Menu } from 'react-burger-menu';

import Wave		from './components/wave';
import Header   from './components/header';
import Footer   from './components/footer';

// Screens
import Home from './views/home';
import About from './views/about';
import CaseStudies from './views/caseStudies';
import Contact from './views/contact';
import Services from './views/services';
import Service from './views/service';
import Technologies from './views/tech';
import CaseStudy from './views/case-study';
import Blog from './views/blog';
import BlogPost from './views/blogPost';
import Accessibility from './views/accessibility';
import AccessibilityToolbar from './components/toolbar';

// Data
import {AccessibilityProvider} from './accessibility-provider';

// Style
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';

/**
 * Application Component
 */
function App() {

    // States
	const [appclasses, setAppclasses] = useState('');
	const [menuOpen, setMenuOpen] = useState(false);
	
    /**
     * Set the page class on the wrapper
     */
	const declareAppClasses = () => {
		let path = window.location.pathname;

		let output = '';

		if( path === '/' )
			output = 'page-home';
		else if( path === '/about' )
			output = 'page-about';
        else if( path.length > 10 && path.substring(0, 10) === '/services/' )
			output = 'page-services';
		else if( path === '/services' )
			output = 'page-services';
		else if( path === '/contact' )
			output = 'page-contact';
		else if( path === '/projects' )
			output = 'page-case-studies';
		else if( path === '/blog' )
			output = 'page-blog';
		else if( path === '/tech')
			output = 'page-tech';
        else if( path === '/accessibility')
			output = 'page-accessibility';
		else if( path.length > 10 && path.substring(0, 10) === '/projects/' )
			output = 'page-case-study';
		else if( path.length > 6 && path.substring(0, 6) === '/blog/' )
			output = 'page-case-study';
			
		setAppclasses( output );
	}
	
    /**
     * Toggle menu
     */
	const handleStateChange = (state) => {
		setMenuOpen(state.isOpen);
    }
	
	useEffect(() => {
		declareAppClasses();
	}, []);
	
	return (
        <AccessibilityProvider>
		    <Router>
			    <div id="wrap" className={appclasses}>

				    <div id="sitebg"><Wave /></div>

                    {window.innerWidth > 700 &&
                    <AccessibilityToolbar />
                    }
                
                    {window.innerWidth < 768 &&
                    <Menu 
                        // noOverlay
                        isOpen={menuOpen}
                        onStateChange={(state) => handleStateChange(state)}
                        width={ 280 }
                        pageWrapId={ "page-wrap" } 
                        outerContainerId={ "wrap" }
                    >
                        <Link to="/" onClick={() => setMenuOpen(false)}>{'ראשי'}</Link>
                        <Link to="/about" onClick={() => setMenuOpen(false)}>{'אודות עמית מורנו'}</Link>
                        <Link to="/services" onClick={() => setMenuOpen(false)}>{'שירותים'}</Link>
                        <Link to="/tech" onClick={() => setMenuOpen(false)}>{'טכנולוגיות'}</Link>
                        <Link to="/projects" onClick={() => setMenuOpen(false)}>{'תיק עבודות'}</Link>
                        <Link to="/blog" onClick={() => setMenuOpen(false)}>{'בלוג'}</Link>
                        <Link to="/contact" onClick={() => setMenuOpen(false)}>{'יצירת קשר'}</Link>
                        <a href="https://www.codenroll.co.il">{'ENGLISH'}</a>
                    </Menu>
                    }

				    <div>
                
                        <Header />
                    
                        <Routes>
                            <Route exact path="/" element={<Home declareAppClasses={declareAppClasses} />}/>
                            <Route exact path="/about" element={<About declareAppClasses={declareAppClasses} />}/>
                            <Route exact path="/services" element={<Services declareAppClasses={declareAppClasses} />}/>
                            <Route exact path="/services/:slug" element={<Service declareAppClasses={declareAppClasses} />}/>
                            <Route exact path="/tech" element={<Technologies declareAppClasses={declareAppClasses} />}/>
                            <Route exact path="/contact" element={<Contact declareAppClasses={declareAppClasses} />}/>
                            <Route exact path="/accessibility" element={<Accessibility declareAppClasses={declareAppClasses} />} />
                            <Route exact path="/blog" element={<Blog  declareAppClasses={declareAppClasses} />} />
                            <Route exact path="/projects" element={<CaseStudies  declareAppClasses={declareAppClasses} />} />
                            <Route exact path="/projects/:slug" element={<CaseStudy declareAppClasses={declareAppClasses} />}/>
                            <Route exact path="/blog/:slug" element={<BlogPost declareAppClasses={declareAppClasses} />}/>
                        </Routes>
					
				        <Footer />
			        </div>
		        </div>
		    </Router>
        </AccessibilityProvider>
	);
}

export default App;
