import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import {
  Container, Breadcrumb, Row, Col,
} from 'react-bootstrap';
import { HomeFilled } from '@ant-design/icons';

const CaseStudies = (props) => {
	const [cases, setCases] = useState([]);
	
	useEffect(() => {
		props.declareAppClasses();
		document.title = "Web Development Case Studies | Code n' Roll";
        fetch("https://admin.codenroll.co.il/wp-json/wp/v2/case_study?per_page=100", {})
            .then(response => {
                return response.json();
            })
            .then(results => {
                setCases(results);
            });
	})
	
	let Item1 = cases.length > 0 ? cases[0] : '';
	let Item2 = cases.length > 1 ? cases[1] : '';
	let Item3 = cases.length > 2 ? cases[2] : '';
	let Item4 = cases.length > 3 ? cases[3] : '';
	
	let Item5 = cases.length > 4 ? cases[4] : '';
	let Item6 = cases.length > 5 ? cases[5] : '';
	let Item7 = cases.length > 6 ? cases[6] : '';
	let Item8 = cases.length > 7 ? cases[7] : '';
	
	let Item9 = cases.length > 8 ? cases[8] : '';
	let Item10 = cases.length > 9 ? cases[9] : '';
	let Item11 = cases.length > 10 ? cases[10] : '';
	let Item12 = cases.length > 11 ? cases[11] : '';
	
	let Item13 = cases.length > 12 ? cases[12] : '';
	let Item14 = cases.length > 13 ? cases[13] : '';
	let Item15 = cases.length > 14 ? cases[14] : '';
	let Item16 = cases.length > 15 ? cases[15] : '';
	
	let Item17 = cases.length > 16 ? cases[16] : '';
	let Item18 = cases.length > 17 ? cases[17] : '';
	let Item19 = cases.length > 18 ? cases[18] : '';
	let Item20 = cases.length > 19 ? cases[19] : '';

		
	return(
		<div className="page-wrap">
			<header className="page-header">
				<Container>
					<h1>תיק עבודות</h1>
					<Breadcrumb>
						<li className="breadcrumb-item"><Link to={'/'} title="ניווט לעמוד הראשי"><HomeFilled /><span className="sr-only">Navigate to Home page</span></Link></li>
						<Breadcrumb.Item active>תיק עבודות</Breadcrumb.Item>
					</Breadcrumb>
				</Container>
			</header>
			
			<section id="hp-case-studies">
				<Container>
					<Row className="hp-use-cases-row">
					{Item1 !== '' &&
						<Col sm={4}>
							<Link to={'/projects/' + Item1.slug} className="box box-image uc-box-long">
								<div className="bg" style={{ backgroundImage: 'url(' + Item1.full_image + ')', }}></div>
								<div className="textual">
									<h3>{ Item1.title_heb }</h3>
								</div>
							</Link>
						</Col>
					}
					{Item2 !== '' &&
						<Col sm={8}>
							<Row>
								<Col sm={12}>
									<Link to={'/projects/' + Item2.slug} className="box box-image uc-box-wide">
										<div className="bg" style={{ backgroundImage: 'url(' + Item2.full_image + ')', }}></div>
										<div className="textual">
											<h3>{ Item2.title_heb }</h3>
										</div>
									</Link>
								</Col>
								
								{Item3 !== '' &&
								<Col sm={6}>
									<Link to={'/projects/' + Item3.slug} className="box box-image uc-box-small">
										<div className="bg" style={{ backgroundImage: 'url(' + Item3.full_image + ')', }}></div>
										<div className="textual">
											<h3>{ Item3.title_heb }</h3>
										</div>
									</Link>
								</Col>
								}
								{Item4 !== '' &&
								<Col sm={6}>
									<Link to={'/projects/' + Item4.slug} className="box box-image uc-box-small">
										<div className="bg" style={{ backgroundImage: 'url(' + Item4.full_image + ')', }}></div>
										<div className="textual">
											<h3>{ Item4.title_heb }</h3>
										</div>
									</Link>
								</Col>
								}
							</Row>
						</Col>
					}
					</Row>
					
					<Row className="hp-use-cases-row hp-use-cases-row-2">
					
					{Item5 !== '' &&
						<Col sm={8}>
							<Row>
								<Col sm={12}>
									<Link to={'/projects/' + Item5.slug} className="box box-image uc-box-wide">
										<div className="bg" style={{ backgroundImage: 'url(' + Item5.full_image + ')', }}></div>
										<div className="textual">
											<h3>{ Item5.title_heb }</h3>
										</div>
									</Link>
								</Col>
								{Item6 !== '' &&
								<Col sm={6}>
									<Link to={'/projects/' + Item6.slug} className="box box-image uc-box-small">
										<div className="bg" style={{ backgroundImage: 'url(' + Item6.full_image + ')', }}></div>
										<div className="textual">
											<h3>{ Item6.title_heb }</h3>
										</div>
									</Link>
								</Col>
								}
								{Item7 !== '' &&
								<Col sm={6}>
									<Link to={'/projects/' + Item7.slug} className="box box-image uc-box-small">
										<div className="bg" style={{ backgroundImage: 'url(' + Item7.full_image + ')', }}></div>
										<div className="textual">
											<h3>{ Item7.title_heb }</h3>
										</div>
									</Link>
								</Col>
								}
							</Row>
						</Col>
					}
					{Item8 !== '' &&
						<Col sm={4}>
							<Link to={'/projects/' + Item8.slug} className="box box-image uc-box-long">
								<div className="bg" style={{ backgroundImage: 'url(' + Item8.full_image + ')', }}></div>
								<div className="textual">
									<h3>{ Item8.title_heb }</h3>
								</div>
							</Link>
						</Col>
					}
					</Row>
					
					<Row className="hp-use-cases-row">
					{Item9 !== '' &&
						<Col sm={4}>
							<Link to={'/projects/' + Item9.slug} className="box box-image uc-box-long">
								<div className="bg" style={{ backgroundImage: 'url(' + Item9.full_image + ')', }}></div>
								<div className="textual">
									<h3>{ Item9.title_heb }</h3>
								</div>
							</Link>
						</Col>
					}
					{Item10 !== '' &&
						<Col sm={8}>
							<Row>
								<Col sm={12}>
									<Link to={'/projects/' + Item10.slug} className="box box-image uc-box-wide">
										<div className="bg" style={{ backgroundImage: 'url(' + Item10.full_image + ')', }}></div>
										<div className="textual">
											<h3>{ Item10.title_heb }</h3>
										</div>
									</Link>
								</Col>
								
								{Item11 !== '' &&
								<Col sm={6}>
									<Link to={'/projects/' + Item11.slug} className="box box-image uc-box-small">
										<div className="bg" style={{ backgroundImage: 'url(' + Item11.full_image + ')', }}></div>
										<div className="textual">
											<h3>{ Item11.title_heb }</h3>
										</div>
									</Link>
								</Col>
								}
								{Item12 !== '' &&
								<Col sm={6}>
									<Link to={'/projects/' + Item12.slug} className="box box-image uc-box-small">
										<div className="bg" style={{ backgroundImage: 'url(' + Item12.full_image + ')', }}></div>
										<div className="textual">
											<h3>{ Item12.title_heb }</h3>
										</div>
									</Link>
								</Col>
								}
							</Row>
						</Col>
					}
					</Row>
					
					<Row className="hp-use-cases-row hp-use-cases-row-2">
					
					{Item13 !== '' &&
						<Col sm={8}>
							<Row>
								<Col sm={12}>
									<Link to={'/projects/' + Item13.slug} className="box box-image uc-box-wide">
										<div className="bg" style={{ backgroundImage: 'url(' + Item13.full_image + ')', }}></div>
										<div className="textual">
											<h3>{ Item13.title_heb }</h3>
										</div>
									</Link>
								</Col>
								{Item14 !== '' &&
								<Col sm={6}>
									<Link to={'/projects/' + Item14.slug} className="box box-image uc-box-small">
										<div className="bg" style={{ backgroundImage: 'url(' + Item14.full_image + ')', }}></div>
										<div className="textual">
											<h3>{ Item14.title_heb }</h3>
										</div>
									</Link>
								</Col>
								}
								{Item15 !== '' &&
								<Col sm={6}>
									<Link to={'/projects/' + Item15.slug} className="box box-image uc-box-small">
										<div className="bg" style={{ backgroundImage: 'url(' + Item15.full_image + ')', }}></div>
										<div className="textual">
											<h3>{ Item15.title_heb }</h3>
										</div>
									</Link>
								</Col>
								}
							</Row>
						</Col>
					}
					{Item16 !== '' &&
						<Col sm={4}>
							<Link to={'/projects/' + Item16.slug} className="box box-image uc-box-long">
								<div className="bg" style={{ backgroundImage: 'url(' + Item16.full_image + ')', }}></div>
								<div className="textual">
									<h3>{ Item16.title_heb }</h3>
								</div>
							</Link>
						</Col>
					}
					</Row>
					
					<Row className="hp-use-cases-row">
					{Item17 !== '' &&
						<Col sm={4}>
							<Link to={'/projects/' + Item17.slug} className="box box-image uc-box-long">
								<div className="bg" style={{ backgroundImage: 'url(' + Item17.full_image + ')', }}></div>
								<div className="textual">
									<h3>{ Item17.title_heb }</h3>
								</div>
							</Link>
						</Col>
					}
					{Item18 !== '' &&
						<Col sm={8}>
							<Row>
								<Col sm={12}>
									<Link to={'/projects/' + Item18.slug} className="box box-image uc-box-wide">
										<div className="bg" style={{ backgroundImage: 'url(' + Item18.full_image + ')', }}></div>
										<div className="textual">
											<h3>{ Item18.title_heb }</h3>
										</div>
									</Link>
								</Col>
								
								{Item19 !== '' &&
								<Col sm={6}>
									<Link to={'/projects/' + Item19.slug} className="box box-image uc-box-small">
										<div className="bg" style={{ backgroundImage: 'url(' + Item19.full_image + ')', }}></div>
										<div className="textual">
											<h3>{ Item19.title_heb }</h3>
										</div>
									</Link>
								</Col>
								}
								{Item20 !== '' &&
								<Col sm={6}>
									<Link to={'/projects/' + Item20.slug} className="box box-image uc-box-small">
										<div className="bg" style={{ backgroundImage: 'url(' + Item20.full_image + ')', }}></div>
										<div className="textual">
											<h3>{ Item20.title_heb }</h3>
										</div>
									</Link>
								</Col>
								}
							</Row>
						</Col>
					}
					</Row>
				</Container>
        	</section>
		</div>
	);
}

export default CaseStudies;