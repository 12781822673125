import React, {useState, createContext, useEffect} from 'react';

export const AccessibilityContext = createContext();

export const AccessibilityProvider = (props) => {
    const [animations, setAnimations] = useState(false);
    const [inctext, setInctext] = useState(false);
    const [dectext, setDectext] = useState(false);
    const [darkconstrast, setDarkconstrast] = useState(false);
    const [brightconstrast, setBrightconstrast] = useState(false);
    const [readable, setReadable] = useState(false);
    
    // Initial Effect
    useEffect(()=> {
    }, []);


    return (
        <AccessibilityContext.Provider 
            value={{
                animations: [animations, setAnimations],
                inctext: [inctext, setInctext],
                dectext: [dectext, setDectext],
                darkconstrast: [darkconstrast, setDarkconstrast],
                brightconstrast: [brightconstrast, setBrightconstrast],
                readable: [readable, setReadable],
            }}
        >
            {props.children}
        </AccessibilityContext.Provider>
    );
}