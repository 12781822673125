import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { Container, Breadcrumb } from 'react-bootstrap';
import { HomeFilled } from '@ant-design/icons';
import parse from 'html-react-parser';

const BlogItem = (props) => {
	const date = new Date(props.item.date);
	
	return(
		<a 
			href={'/blog/' + props.item.slug} 
			key={props.index} 
			className={'blog-item'}>
			{props.item.title &&
				<h2>{parse(props.item.title.rendered)}</h2>
				
			}
			{props.item.date &&
				<p>{date.toLocaleDateString("he-IL")}</p>
			}
			{props.item.excerpt &&
				<p>{parse(props.item.excerpt.rendered)}</p>
			}
			
		</a>
	);
}

const Blog = (props) => {
	const [posts, setPosts] = useState([]);
	
	useEffect(() => {
		props.declareAppClasses();
		document.title = "בלוג | עמית מורנו";
        fetch("https://admin.codenroll.co.il/wp-json/wp/v2/posts?per_page=100", {})
            .then(response => {
                return response.json();
            })
            .then(results => {
                setPosts(results);
            });
	});
	return(
		<div className="page-wrap">
			<header className="page-header">
				<Container>
					<h1>בלוג</h1>
					<Breadcrumb>
						<li className="breadcrumb-item"><Link to={'/'} title="ניווט לעמוד הראשי"><HomeFilled /><span className="sr-only">Navigate to Home page</span></Link></li>
						<Breadcrumb.Item active>בלוג</Breadcrumb.Item>
					</Breadcrumb>
				</Container>
			</header>
			
			<section id="blog">
				<Container>
					{posts.length > 0 &&
					<div id="blog-loop">
						{posts.map( (item, index) => {
							return(
								<BlogItem index={index} key={index} item={item} />
							);
						})}
					</div>
					}
					
				</Container>
        	</section>
		</div>
	);
}

export default Blog;