import React, {useEffect} from "react";
import {
  Row, Col, Container, Breadcrumb,
} from 'react-bootstrap';
import { Link } from "react-router-dom";
import { MailFilled, CompassFilled, PhoneFilled, HomeFilled } from '@ant-design/icons';
import ContactForm from '../components/contactForm';

const Contact = (props) => {
	useEffect(() => {
		props.declareAppClasses();
	}, [props]);
  
	return(
		<div className="page-wrap">
        <header className="page-header page-header-nomarg">
            <Container>
              <h1>יצירת קשר</h1>
              
              <Breadcrumb>
                <li className="breadcrumb-item"><Link to={'/'} title="ניווט לעמוד הראשי"><HomeFilled /><span className="sr-only">ניווט לעמוד הראשי</span></Link></li>
                <Breadcrumb.Item active>יצירת קשר</Breadcrumb.Item>
              </Breadcrumb>
            </Container>
          </header>
          
        <Container>
            <Row>
                <Col lg={5}>
                    <div className="box widget">
                        <div className="widget-heading-wrap">
                            <h2 className="widget-heading">פרטי יצירת קשר</h2>
                        </div>
                
                        <dl className="sl-horizontal">
                            <dt><CompassFilled /> כתובת</dt>
                            <dd>ההרדוף 18, עתלית<br />ישראל</dd>
                            
                            <dt><PhoneFilled /> טלפון</dt>
                            <dd><a href="0534626311">053-4626311</a></dd>
                            
                            <dt><MailFilled /> דוא״ל</dt>
                            <dd><a href="mailto:a@Codenroll.co.il">a@Codenroll.co.il</a></dd>
                        </dl>
                    </div>
                </Col>
                <Col lg={1}></Col>
                <Col lg={6}>
                  <div className="box widget">
                    <div className="widget-heading-contact">
                        <h2 className="widget-heading">זה הזמן לשוחח על הפרויקט שלך!</h2>
                        <p>הצוות שלנו זמין לענות על כל שאלותיכם:</p>
                    </div>
                    <ContactForm />
                  </div>
                </Col>
            </Row>
        </Container>
      </div>
	);
}

export default Contact;