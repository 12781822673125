import React, {useState, useEffect} from "react";
import parse from 'html-react-parser';
import { Link , useParams} from "react-router-dom";
import {
  Container, Breadcrumb, Row, Col,
} from 'react-bootstrap';
import { HomeFilled } from '@ant-design/icons';

const CaseStudy = (props) => {
	const [mockClass, setMockClass] = useState('');
	const [data, setData] = useState(null);
	
	// TODO
	let match = useParams(); //'/case-study/:slug' 

	useEffect(() => {
		props.declareAppClasses();
		fetch("https://admin.codenroll.co.il/wp-json/wp/v2/case_study?slug=" + match.slug, {})
            .then(response => {
                return response.json();
            })
            .then(results => {
            	document.title = results[0].title.rendered + " (Case Study) | Code n' Roll";
	              setData(results[0]);
	              
	              
	              setTimeout(function(){
	              	setMockClass('in');
	              }, 1200);
            });
		
	});
	
	let title = data && data.title_heb ? parse(data.title_heb) : '';
	// let intro = data && data.intro ? parse(data.intro) : '';
	let challenge = data && data.challenge_heb ? parse(data.challenge_heb) : '';
	let solution = data && data.solution_heb ? parse(data.solution_heb) : '';
	let mockup = data && data.mockup ? data.mockup : '';
	let mockup2 = data && data.mockup2 ? data.mockup2 : '';
	let industry = data && data.industry_heb ? data.industry_heb : '';
	let hours = data && data.hours ? data.hours : '';
	let tech = data && data.tech ? data.tech : '';
	let integrations = data && data.integrations ? data.integrations : '';
	let duration = data && data.duration_heb ? data.duration_heb : '';
	
	return(
		<div className="page-wrap no-margbottom">
			{data &&
			<header className={"page-header page-header-with-lead " + mockClass} id="page-haeder-case-study">
				<Container>
					<Row>
						<Col lg={6}>
						{title !== '' &&
						<div className="content">
							<h1>{title}</h1>
							{/*intro !== '' &&
							<div className="lead"><p>{intro}</p></div>
							*/}
							{window.innerWidth > 481 &&
								<Breadcrumb>
									<li className="breadcrumb-item"><Link to={'/'} title="ניווט לעמוד הראשי"><HomeFilled /><span className="sr-only">Navigate to Home page</span></Link></li>
									<li className="breadcrumb-item"><Link to={'/projects'} title="ניווט אל כל הפרויקטים">פרויקטים</Link></li>
									<Breadcrumb.Item active>{title}</Breadcrumb.Item>
								</Breadcrumb>
							}
						</div>
					}
						</Col>
						<Col lg={6}>
							{mockup !== '' &&
							<div className={"mockup-wrap " + mockClass}>
								<div className="mockup">
									<img src={mockup} alt="הדמיה" />
								</div>
								{mockup2 !== '' &&
								<div className="mockup">
									<img src={mockup2} alt="הדמיה" />
								</div>
								}
							</div>
							}
						</Col>
					</Row>
					
				</Container>
			</header>
			}
			{data &&
			<div>
				<Container>
					<Row>
						<Col lg={6} sm={12} id="cs-challenge-col">
							<h2>האתגר</h2>
							{challenge}
						</Col>
						<Col lg={6} sm={12}>
							<div className="box" id="case-study-overview">
								<h2 className="h4">סיקור</h2>
								<dl className="dl-horizontal">
								{industry !== '' &&
								<div>
										<dt>תעשיה</dt>
										<dd>{industry}</dd>
									</div>
								}
								{duration !== '' &&
								<div>
										<dt>משך</dt>
										<dd>{duration}</dd>
									</div>
								}
								{hours !== '' &&
								<div>
									<dt>היקף שעות</dt>
									<dd>{hours}</dd>
									</div>
								}
								{tech !== '' &&
								<div>
									<dt>טכנולוגיות</dt>
									<dd>{tech}</dd>
									</div>
								}
								{integrations !== '' &&
								<div>
									<dt>אינטגרציות</dt>
									<dd>{integrations}</dd>
									</div>
								}
									
								</dl>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
			}
			{data &&
			<div id="case-study-solution">
				<Container>
					<Row>
						<Col lg={6}>
							<h2>הפתרון</h2>
							{solution}
						</Col>
					</Row>
				</Container>
			</div>
			}
		</div>
	);
}

export default CaseStudy;