import React, {useState, useEffect} from "react";
import parse from 'html-react-parser';
import { Link, useParams} from "react-router-dom";
import {
  Container, Breadcrumb, Row, Col,
} from 'react-bootstrap';
import { HomeFilled } from '@ant-design/icons';

const BlogItem = (props) => {
	const date = new Date(props.item.date);
	
	return(
		<a 
			href={'/blog/' + props.item.slug} 
			key={props.index} 
			className={'recent-item'}>
			{props.item.title &&
				<h3>{parse(props.item.title.rendered)}</h3>
				
			}
			{props.item.date &&
				<p>{date.toLocaleDateString("he-IL")}</p>
			}
			{props.item.excerpt &&
				<p>{parse(props.item.excerpt.rendered)}</p>
			}
			
		</a>
	);
}

const BlogPost = (props) => {
	const [data, setData] = useState(null);
	const [recents, setRecents] = useState([]);
	
	let match = useParams();

	useEffect(() => {
		props.declareAppClasses();
		fetch("https://admin.codenroll.co.il/wp-json/wp/v2/posts?slug=" + match.slug, {})
            .then(response => {
                return response.json();
            })
            .then(results => {
            	document.title = results[0].title.rendered + " | עמית מורנו";
	              setData(results[0]);
            });
        ////
        fetch("https://admin.codenroll.co.il/wp-json/wp/v2/posts?per_page=4", {})
            .then(response => {
                return response.json();
            })
            .then(results => {
                setRecents(results);
            });
		
	}, [props, match.slug]);
	
	let title = data && data.title ? parse(data.title.rendered) : '';
	let content = data && data.content ? parse(data.content.rendered) : '';
	
	return(
		<div className="page-wrap no-margbottom">
			{data &&
			<header className={"page-header page-header-blogpost page-header-small-mb"} id="page-haeder-blog-post">
				<Container>
					<Row>
						<Col lg={8}>
						{title !== '' &&
						<div className="content">
							<h1>{title}</h1>
							{/*intro !== '' &&
							<div className="lead"><p>{intro}</p></div>
							*/}
							{window.innerWidth > 481 &&
								<Breadcrumb>
									<li className="breadcrumb-item"><Link to={'/'} title="ניווט לעמוד הראשי"><HomeFilled /><span className="sr-only">ניווט לעמוד הראשי</span></Link></li>
									<li className="breadcrumb-item"><Link to={'/blog'} title="ניווט אל כל הפוסטים">בלוג</Link></li>
									<Breadcrumb.Item active>{title}</Breadcrumb.Item>
								</Breadcrumb>
							}
						</div>
					}
						</Col>
					</Row>
					
				</Container>
			</header>
			}
			{data &&
			<div id="blog-post-content">
				<Container>
					<Row>
						<Col lg={7}>
							{content}
						</Col>
						<Col lg={1}></Col>
						<Col lg={4}>
							{recents.length > 0 &&
							<div id="blog-recents">
								<h2>מאמרים נוספים שעשוים לעניין אותך</h2>
								{recents.map( (item, index) => {
									return(
										<BlogItem index={index} key={index} item={item} />
									);
								})}
							</div>
							}
						</Col>
					</Row>
				</Container>
			</div>
			}
		</div>
	);
}

export default BlogPost;